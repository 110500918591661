
import { useRef } from "react";
import { VideoDetailsPojo } from "../../services/NewBusinessSignUpRequest/NewBusinessSignUpRequestPojo/VideoDetailsPojo";

const IndividualNewBusinessSignUpRequestVideo = (props: { videoDetails: VideoDetailsPojo, key: number }) => {
    const videoList = props.videoDetails;
    const videoRef = useRef<HTMLVideoElement>(null);
    return (
        <>
            <div className="col-md-4 mb-2" key={videoList.videoId}>
                <video className="newBusinessSignUpVideo" ref={videoRef} controls poster={videoList.thumbnail}> <source src={videoList.videoUrl} /> </video>
            </div>
        </>
    )

}

export default IndividualNewBusinessSignUpRequestVideo;