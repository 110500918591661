import { Language } from "../../language/English";

const AllNewBusinessSignUpRequest = (props: any) => {

    return(
        <>
            <div className="list cardList">
                <ul>
                    <li></li>
                    <li>{ Language.SERIAL_NUMBER }</li>
                    <li>{ Language.IS_READ }</li>
                    <li>{ Language.BUSINESS_IMAGES }</li>
                    <li>{ Language.PRICE_LIST_IMAGES }</li>
                    <li>{ Language.VIDEO }</li>
                    <li>{ Language.BUSINESS_TIMING }</li>
                    <li>{ Language.BUSINESS_NAME }</li>
                    <li>{ Language.CONTACT_PERSON_NAME }</li>
                    <li>{ Language.EMAIL }</li>
                    <li>{ Language.PHONE_NUMBER }</li>
                    <li>{ Language.WHATSAPP_PHONE_NUMBER }</li>
                    <li>{ Language.ADDRESS }</li>
                    <li>{ Language.WHO_IS_ALLOWED }</li>
                    <li>{ Language.CREATED_ON }</li>
                </ul>
                {
                    props.children
                }
            </div>
        </>
    )
}

export default AllNewBusinessSignUpRequest;