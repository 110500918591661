import { Layout } from "../../components/Layout";
import { useEffect, useState } from "react";
import { NewBusinessSignUpRequestService } from "../../services/NewBusinessSignUpRequest/NewBusinessSignUpRequestService";
import { Language } from "../../language/English";
import Loader from "../../components/Layout/Loader";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import { NewBusinessSignUpRequestPojo } from "../../services/NewBusinessSignUpRequest/NewBusinessSignUpRequestPojo/NewBusinessSignUpRequestPojo";
import IndividualNewBusinessSignUpRequest from "./IndividualNewBusinessSignUpRequest";
import AllNewBusinessSignUpRequest from "./AllNewBusinessSignUpRequest";

const NewBusinessSignUpRequest = () => {
    const [newBusinessSignUpRequest, setNewBusinessSignUpRequest] = useState<NewBusinessSignUpRequestPojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    
    const notification = new Notification();
    const logger = new Logger();
    
    const fetchNewBusinessSignUpRequest = async() => {
        try{
            const newBusinessSignUpRequestService = new NewBusinessSignUpRequestService();
            const response = await newBusinessSignUpRequestService.getAll();
            if(response){
                setNewBusinessSignUpRequest(response.newBusinessSignUpRequest);
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }
    useEffect(() => {
        setShowLoader(true);
        fetchNewBusinessSignUpRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <Layout>
                { showLoader ? <Loader /> : null }
                <AllNewBusinessSignUpRequest>
                {
                    newBusinessSignUpRequest.length > 0 && newBusinessSignUpRequest.map( (newBusinessSignUpRequest, index) => (
                        <IndividualNewBusinessSignUpRequest newBusinessSignUpRequest={newBusinessSignUpRequest} key={newBusinessSignUpRequest.newBusinessSignUpRequestId} count={index}/>
                    ))
                }
                </AllNewBusinessSignUpRequest>
        </Layout>
      </div>
    )
}
export default NewBusinessSignUpRequest;