import { Language } from "../../language/English";
import { NEW_BUSINESS_SIGN_UP_REQUEST_STATUS_DELETED, NEW_BUSINESS_SIGN_UP_REQUEST_IS_READ_ACTIVE, NEW_BUSINESS_SIGN_UP_REQUEST_DELETE_TYPE, NEW_BUSINESS_SIGN_UP_REQUEST_READ_TYPE } from "../../services/NewBusinessSignUpRequest/NewBusinessSignUpRequestConstant";

import { NewBusinessSignUpRequestPojo } from "../../services/NewBusinessSignUpRequest/NewBusinessSignUpRequestPojo/NewBusinessSignUpRequestPojo";

import { CButton } from '@coreui/react';
import Loader from "../../components/Layout/Loader";
import ConfirmationBoxModal from "../../components/Layout/ConfirmationBoxModal";
import { useState, useEffect, Key } from 'react';
import { NewBusinessSignUpRequestService } from "../../services/NewBusinessSignUpRequest/NewBusinessSignUpRequestService";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import CIcon from "@coreui/icons-react";
import { cilCheckAlt } from "@coreui/icons";
import Modal from "../../components/Layout/Modal";
import AllNewBusinessSignUpRequestImage from "./AllNewBusinessSignUpRequestImage";
import IndividualNewBusinessSignUpRequestImage from "./IndividualNewBusinessSignUpRequestImage";
import AllNewBusinessSignUpRequestVideo from "./AllNewBusinessSignUpRequestVideo";
import IndividualNewBusinessSignUpRequestVideo from "./IndividualNewBusinessSignUpRequestVideo";

const IndividualNewBusinessSignUpRequest = (props: { newBusinessSignUpRequest: NewBusinessSignUpRequestPojo, key: number, count: number }) => {
    const newBusinessSignUpRequest = props.newBusinessSignUpRequest;
    const newBusinessSignUpRequestImage = newBusinessSignUpRequest.imageDetails;
    const priceListImageDetailsRequestImage = newBusinessSignUpRequest.priceListImageDetails;
    const requestVideoDetails = newBusinessSignUpRequest.videoDetails;
    const requestOpeningClosingTimings = newBusinessSignUpRequest.openingClosingTimings;
    // console.log(requestOpeningClosingTimings);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [newBusinessSignUpRequestStatus, setNewBusinessSignUpRequestStatus] = useState<number>();
    const [showAction, setShowAction] = useState<boolean>(true);
    const [confirmationBoxShow, setConfirmationBoxShow] = useState(false);
    const [deleteConfirmationBoxShow, setDeleteConfirmationBoxShow] = useState(false);
    const [visible, setVisible] = useState(false);
    const [viewImage, setViewImage] = useState(false);
    const [viewPriceListImage, setViewPriceListImage] = useState(false);
    const [viewVideo, setViewVideo] = useState(false);
    const [viewOpeningClosingTimings, setViewOpeningClosingTimings] = useState(false);
    const [getPriceListImage, setPriceListImage] = useState(false);
    const [getVideo, setVideo] = useState(false);
    const [getOpeningClosingTimings, setOpeningClosingTimings] = useState(false);

    const handleConfirmation = (status: number) => {
        setConfirmationBoxShow(!confirmationBoxShow);
    }

    const deleteHandleConfirmation = (status: number) => {
        setDeleteConfirmationBoxShow(!deleteConfirmationBoxShow);
    }

    const deleteNewBusinessSignUpRequest = (newBusinessSignUpRequestId: number) => {
        changeStatusClick(newBusinessSignUpRequestId, NEW_BUSINESS_SIGN_UP_REQUEST_STATUS_DELETED, NEW_BUSINESS_SIGN_UP_REQUEST_DELETE_TYPE);
    }

    const readNewBusinessSignUpRequest = (newBusinessSignUpRequestId: number) => {
        changeStatusClick(newBusinessSignUpRequestId, NEW_BUSINESS_SIGN_UP_REQUEST_IS_READ_ACTIVE, NEW_BUSINESS_SIGN_UP_REQUEST_READ_TYPE);
    }

    const handleDisplay = () => {
        if (newBusinessSignUpRequest.isRead) {
            setShowAction(false);
        }

        if (newBusinessSignUpRequestImage.length > 0) {
            setViewImage(true);
        }

        if (priceListImageDetailsRequestImage.length > 0) {
            setViewPriceListImage(true);
        }

        if (requestVideoDetails.length > 0) {
            setViewVideo(true);
        }

        if (requestOpeningClosingTimings.length > 0) {
            setViewOpeningClosingTimings(true);
        }

    }

    const notification = new Notification();
    const logger = new Logger();

    const changeStatusClick = async (newBusinessSignUpRequestId: number, status: number, type: string) => {
        setShowLoader(true);
        try {
            const newBusinessSignUpRequestService = new NewBusinessSignUpRequestService();
            let response: any;
            if (type === NEW_BUSINESS_SIGN_UP_REQUEST_DELETE_TYPE) {
                response = await newBusinessSignUpRequestService.delete(newBusinessSignUpRequestId, status);
            } else if (type === NEW_BUSINESS_SIGN_UP_REQUEST_READ_TYPE) {
                response = await newBusinessSignUpRequestService.read(newBusinessSignUpRequestId, status);
            }
            if (response) {
                if (type === NEW_BUSINESS_SIGN_UP_REQUEST_DELETE_TYPE) {
                    notification.success(Language.DELETED);
                    setNewBusinessSignUpRequestStatus(status);
                } else if (type === NEW_BUSINESS_SIGN_UP_REQUEST_READ_TYPE) {
                    notification.success(Language.READ);
                    setShowAction(false);
                    setNewBusinessSignUpRequestStatus(status);
                }
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e) {
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }

    useEffect(() => {
        handleDisplay();
    });

    return (
        <>
            {showLoader ? <Loader /> : null}
            {
                newBusinessSignUpRequestStatus === NEW_BUSINESS_SIGN_UP_REQUEST_STATUS_DELETED ? null :

                    <ul key={newBusinessSignUpRequest.newBusinessSignUpRequestId} className="card-list-row" >
                        <div className="close-button" onClick={() => deleteHandleConfirmation(NEW_BUSINESS_SIGN_UP_REQUEST_STATUS_DELETED)}>
                            <span>X</span>
                            {
                                deleteConfirmationBoxShow ?
                                    <ConfirmationBoxModal
                                        title={newBusinessSignUpRequest.businessName}
                                        message={Language.NEW_BUSINESS_SIGN_UP_REQUEST_DELETE_CONFIRMATION}
                                        buttonOneText={Language.YES}
                                        buttonTwoText={Language.CLOSE}
                                        buttonOneCallable={deleteNewBusinessSignUpRequest}
                                        buttonTwoCallable={deleteHandleConfirmation}
                                        buttonOneCallBackFunctionParams={newBusinessSignUpRequest.newBusinessSignUpRequestId}
                                        buttonTwoCallBackFunctionParams={null} />
                                    : null
                            }
                        </div>
                        <li className="serial-number" data-label={Language.SERIAL_NUMBER}>{props.count + 1}</li>

                        <li data-label={Language.IS_READ}>
                            {
                                showAction ?
                                    <>
                                        <CButton size="sm" variant="outline" color="primary" onClick={() => handleConfirmation(NEW_BUSINESS_SIGN_UP_REQUEST_IS_READ_ACTIVE)}>
                                            {Language.MARK_AS_READ}
                                        </CButton>
                                        {
                                            confirmationBoxShow ?
                                                <ConfirmationBoxModal
                                                    title={newBusinessSignUpRequest.businessName}
                                                    message={Language.NEW_BUSINESS_SIGN_UP_REQUEST_READ_CONFIRMATION}
                                                    buttonOneText={Language.YES}
                                                    buttonTwoText={Language.CLOSE}
                                                    buttonOneCallable={readNewBusinessSignUpRequest}
                                                    buttonTwoCallable={handleConfirmation}
                                                    buttonOneCallBackFunctionParams={newBusinessSignUpRequest.newBusinessSignUpRequestId}
                                                    buttonTwoCallBackFunctionParams={null} />
                                                : null
                                        }
                                    </>
                                    : <CIcon className="read-icon" icon={cilCheckAlt} />
                            }

                        </li>

                        <li data-label={Language.BUSINESS_IMAGES}>

                            {
                                viewImage ?
                                    <>
                                        <span className="new-business-request-image" onClick={() => setVisible(!visible)}>{Language.VIEW_IMAGE}</span>
                                        {
                                            <>
                                                <Modal title={newBusinessSignUpRequest.businessName} visible={visible} className="new-business-sign-up-request-modal">
                                                    <AllNewBusinessSignUpRequestImage>
                                                        {
                                                            newBusinessSignUpRequestImage.length > 0 && newBusinessSignUpRequestImage.map((newBusinessSignUpRequestImage, index) => (
                                                                <IndividualNewBusinessSignUpRequestImage newBusinessSignUpRequestImage={newBusinessSignUpRequestImage} key={newBusinessSignUpRequestImage.imageId} />
                                                            ))
                                                        }
                                                    </AllNewBusinessSignUpRequestImage>
                                                </Modal>
                                            </>
                                        }
                                    </>
                                    : 'N/A'
                            }
                        </li>


                        <li data-label={Language.PRICE_LIST_IMAGES}>
                            {
                                viewPriceListImage ?
                                    <>
                                        <span className="new-business-request-image" onClick={() => setPriceListImage(!getPriceListImage)}>{Language.VIEW_IMAGE}</span>
                                        {
                                            <>
                                                <Modal title={newBusinessSignUpRequest.businessName} visible={getPriceListImage} className="new-business-sign-up-request-modal">
                                                    <AllNewBusinessSignUpRequestImage>
                                                        {
                                                            priceListImageDetailsRequestImage.length > 0 && priceListImageDetailsRequestImage.map((newBusinessSignUpRequestImage, index) => (
                                                                <IndividualNewBusinessSignUpRequestImage newBusinessSignUpRequestImage={newBusinessSignUpRequestImage} key={newBusinessSignUpRequestImage.imageId} />
                                                            ))
                                                        }
                                                    </AllNewBusinessSignUpRequestImage>
                                                </Modal>
                                            </>
                                        }
                                    </>
                                    : 'N/A'
                            }
                        </li>

                        <li data-label={Language.VIDEO}>
                            {
                                viewVideo ?
                                    <>
                                        <span className="new-business-request-image" onClick={() => setVideo(!getVideo)}>{Language.VIEW_VIDEO}</span>
                                        {
                                            <>
                                                <Modal title={newBusinessSignUpRequest.businessName} visible={getVideo} className="new-business-sign-up-request-modal">
                                                    <AllNewBusinessSignUpRequestVideo>
                                                        {
                                                            requestVideoDetails.length > 0 && requestVideoDetails.map((videoDetails, index) => (
                                                                <IndividualNewBusinessSignUpRequestVideo videoDetails={videoDetails} key={videoDetails.videoId} />
                                                            ))
                                                        }
                                                    </AllNewBusinessSignUpRequestVideo>
                                                </Modal>
                                            </>
                                        }
                                    </>
                                    : 'N/A'
                            }
                        </li>

                        <li data-label={Language.BUSINESS_TIMING}>

                            {
                                viewOpeningClosingTimings ?
                                    <>
                                        <span className="new-business-request-image" onClick={() => setOpeningClosingTimings(!getOpeningClosingTimings)}>{Language.VIEW_TIMING}</span>
                                        {
                                            <>
                                                <Modal title={newBusinessSignUpRequest.businessName} visible={getOpeningClosingTimings} className="booking-modal">
                                                    <table className="timing-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Day</th>
                                                                <th>Opening</th>
                                                                <th>Closing</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {requestOpeningClosingTimings.map((timing: { [x: string]: { opening: any; closing: any; }; }, index: Key | null | undefined) => {
                                                                const day = Object.keys(timing)[0];
                                                                const { opening, closing } = timing[day];
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                                                                        <td>{opening}</td>
                                                                        <td>{closing}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </Modal>
                                            </>
                                        }
                                    </>
                                    : 'N/A'
                            }
                        </li>

                        <li data-label={Language.BUSINESS_NAME}>{newBusinessSignUpRequest.businessName}</li>
                        <li data-label={Language.CONTACT_PERSON_NAME}>{newBusinessSignUpRequest.contactPersonName}</li>
                        <li data-label={Language.EMAIL}>{newBusinessSignUpRequest.email}</li>
                        <li data-label={Language.PHONE_NUMBER}>{newBusinessSignUpRequest.phoneNumber}</li>
                        <li data-label={Language.WHATSAPP_PHONE_NUMBER}>{newBusinessSignUpRequest.whatsappNumber}</li>
                        <li data-label={Language.ADDRESS}>{newBusinessSignUpRequest.address}</li>
                        <li data-label={Language.WHO_IS_ALLOWED}>{newBusinessSignUpRequest.whoIsAllowed ? newBusinessSignUpRequest.whoIsAllowed : 'N/A'}</li>
                        <li data-label={Language.CREATED_ON}>{newBusinessSignUpRequest.createdOn}</li>
                    </ul>
            }

        </>
    )
}
export default IndividualNewBusinessSignUpRequest;